import { Order } from '@medusajs/medusa';
import axios from 'axios';
import React, { createContext } from 'react';

const PAGE_SIZE = 24;
const defaultOrderContext = {
  pageSize: PAGE_SIZE,
  actions: {
    listOrders: async (offset?: number): Promise<OrderResponse> => ({
      orders: [],
      count: 0,
      offset: 0,
      limit: 24,
    }),
  },
};

interface OrderResponse {
  orders: Order[];
  count: number;
  offset: number;
  limit: number;
}

const OrderContext = createContext(defaultOrderContext);

export default OrderContext;

export const OrderProvider = (props) => {
  const listOrders = async (
    offset: number = 0,
    limit: number = PAGE_SIZE
  ): Promise<OrderResponse> => {
    const response = await axios.get<OrderResponse>('/store/orders', {
      params: {
        offset,
        limit,
      },
    });

    return response.data;
  };

  return (
    <OrderContext.Provider
      {...props}
      value={{
        pageSize: PAGE_SIZE,
        actions: {
          listOrders,
        },
      }}
    />
  );
};
